import styled from "@emotion/styled";
import { OmitKnown } from "@nna/core";
import { useField } from "formik";
import { useMemo } from "react";
import { PickDeep } from "type-fest";

import { DotPath } from "../../../../utils/types/DotPath";
import { FormTable, Tooltips } from "../../../ui-atoms/components";
import {
	SelectUtils,
	SelectWithOptions,
	SelectWithOptionsProps,
} from "../SelectWithOptions";
import { FocusInputProps } from "./FocusInput";
import { UnfocusedGenericValue } from "../../../ui-atoms/components/form-table";
import { Layout } from "../../../ui-layout";
import { useFormikErrorDeepCheck } from "../Formik/useFormikErrorDeepCheck";
import { selectCellSX } from "../Select/selectCellSX";

const DecoratorsWrapper = styled(Layout.Lined)`
	gap: 4px;
`;
const SelectOptionWrapper = styled.div``;

/** Props for {@link SelectField} */
export interface SelectFieldProps<V, N, Multiple extends boolean = false>
	extends OmitKnown<FormTable.TableCellProps, "children" | "whenFocused">,
		Pick<FocusInputProps, "disabled" | "placeholder" | "unfocusedError">,
		Pick<
			SelectWithOptionsProps<V, Multiple>,
			"isOptionEqualToValue" | "multiple" | "options"
		> {
	/** Name of the input */
	name: N;
	/** Override select props */
	select?: OmitKnown<
		SelectWithOptionsProps<V, Multiple>,
		| "disabled"
		| "isOptionEqualToValue"
		| "multiple"
		| "onBlur"
		| "onChange"
		| "options"
		| "placeholder"
		| "value"
	>;
}
/**
 * Wraps {@link SelectWithOptions} in a Formik {@link Field}.
 *
 * Note:
 * - As does {@link SelectWithOptions},
 * it uses the `label`, else the `children` of the selected option to show the unfocused value.
 *
 * @template T the 'FormValue' this field appears
 */
export function SelectField<
	T extends object,
	const N extends DotPath<T>,
	Multiple extends boolean = false,
	V = PickDeep<T, N>[keyof PickDeep<T, N>],
>(props: SelectFieldProps<V, N, Multiple>) {
	const {
		disabled = false,
		isOptionEqualToValue,
		multiple,
		name,
		options,
		placeholder,
		select: { endDecorator, startDecorator, ...selectProps } = {},
		unfocusedError = true,
		...cellProps
	} = props;

	const [field, , helper] = useField<V>(name);

	// Needed to handle required inputs
	const error = useFormikErrorDeepCheck(name);

	const selectedOptions = useMemo(
		() =>
			SelectUtils.extractSelectedOptions<V>({
				isOptionEqualToValue,
				options,
				value: field.value as never,
			}),
		[isOptionEqualToValue, options, field.value],
	);
	const selectValue = useMemo(
		() =>
			SelectUtils.transformValues(
				selectedOptions.map(({ value }) => value),
				multiple,
			),
		[selectedOptions, multiple],
	);

	return (
		<FormTable.FocusableTableCell
			{...cellProps}
			whenFocused={
				<SelectWithOptions
					variant="plain"
					{...selectProps}
					autoFocus
					disabled={disabled}
					endDecorator={
						error ? (
							<DecoratorsWrapper>
								<Tooltips.Error title={error} />

								{endDecorator}
							</DecoratorsWrapper>
						) : (
							endDecorator
						)
					}
					multiple={multiple}
					name={name}
					onBlur={field.onBlur}
					onChange={(event, value) =>
						// For some reason, event can be `null` when `value` changes
						event && void helper.setValue(value as never)
					}
					options={options}
					startDecorator={startDecorator}
					sx={selectCellSX}
					value={selectValue as never}
				/>
			}
		>
			<FormTable.FormCellInnerWrapper>
				<UnfocusedGenericValue
					disabled={disabled}
					endDecorator={endDecorator}
					placeholder={placeholder}
					startDecorator={startDecorator}
				>
					{!!selectedOptions.length &&
						selectedOptions.map(({ children, key, label }) => (
							<SelectOptionWrapper
								key={key}
								data-testid={`cell-input/select-field/option/${key}`}
							>
								{label ?? children}
							</SelectOptionWrapper>
						))}
				</UnfocusedGenericValue>

				{!!(error && unfocusedError) && (
					<Tooltips.Error title={error} />
				)}
			</FormTable.FormCellInnerWrapper>
		</FormTable.FocusableTableCell>
	);
}
