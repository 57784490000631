import { OmitKnown } from "@nna/core";
import React from "react";

import { FormTable, Tooltips } from "../../../ui-atoms/components";
import {
	RadioGroupField,
	RadioGroupFieldNameOf,
	RadioGroupFieldProps,
} from "../Formik/RadioGroupField";
import { useFormikErrorDeepCheck } from "../Formik/useFormikErrorDeepCheck";
import { RadioValue } from "../Radio";

/**
 * Wraps {@link FocusInput} in a Formik {@link Field}.
 *
 * Note:
 * - It automatically adds a default `endDecorator` on type `currency`
 *
 * @template T the full object this field validates (preferred) or a simple string
 */
export function RadioGroupFieldCell<
	T extends object,
	const N extends RadioGroupFieldNameOf<T>,
>(
	props: OmitKnown<FormTable.TableCellProps, "children" | "whenFocused"> &
		RadioGroupFieldProps<
			Extract<Pick<T, N>[keyof Pick<T, N>], RadioValue>,
			N
		>,
) {
	const { name } = props;

	// Needed to handle required inputs
	const error = useFormikErrorDeepCheck(name);

	return (
		<FormTable.UnfocusableTableCell colSpan={props.colSpan} error={!!error}>
			<RadioGroupField<T, N> {...props} />

			{error ? <Tooltips.Error title={error} /> : null}
		</FormTable.UnfocusableTableCell>
	);
}
