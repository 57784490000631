import { ChangeState } from "~/common/entry";

import { FormikMultilineRow, MultilineRowState } from "./MultilineForm";

/**
 * Helper function to generate a Multiline initial values array
 *
 * @param items the items listed in the Multiline
 * @param accessorId accessor function that gets the _id of the item
 * @param defaultState the default state for the Multiline
 * @returns an array of Multiline-formatted initial values
 */
export function createMultilineInitialValues<T>(
	items: Array<ValueWithChangeState<T>>,
	accessorId: (item: T) => number,
	defaultState: MultilineRowState = "NONE",
) {
	return items.filter(filterOutChangeStateDeleted).map(item => ({
		_id: accessorId(item),
		state: defaultState,
		value: item,
	}));
}

/**
 * Helper function to calculate the sum total of a list of items
 *
 * @param items the list of items
 * @param accessorValue accessor function that gets the value to sum
 * @returns a total number
 */
export function calculateTotalFor<T>(
	items: T[],
	accessorValue: (item: T) => number,
): number {
	return items.reduce((acc, item) => acc + accessorValue(item), 0);
}

/** So that we can filter out values (= entities) that have been soft-deleted */
export type ValueWithChangeState<T> = T & {
	changeState?: { type: ChangeState.Type };
};

/**
 * Helper function for Array.filter, which filters out values that have been (soft-)deleted
 */
export function filterOutChangeStateDeleted<T>(
	value?: ValueWithChangeState<T>,
): boolean {
	return (
		value?.changeState?.type !== "DELETED" &&
		value?.changeState?.type !== "REMOVED"
	);
}

/**
 * Helper function that extract the 'value' entries from a Multiline (formik) state list
 */
export function getMultilineValues<T>(
	multilineState: Array<FormikMultilineRow<T>>,
): T[] {
	return multilineState
		.filter(row => row.state !== "DELETE")
		.map(row => row.value) as T[]; // We cast because we know that the state is not DELETE (but TS doesn't)
}
