import { FormTable } from "..";

/** Props for {@link UnfocusableTableCell} */
export interface UnfocusableTableCellProps
	extends React.TdHTMLAttributes<HTMLTableCellElement> {
	/** The content of the cell */
	children: React.ReactNode;
	error?: FormTable.FormCellInnerWrapperProps["error"];
}

/**
 * Table cell that simply display some content, with unfocused cell styling
 *
 * @param props To create the node
 */
export const UnfocusableTableCell = (props: UnfocusableTableCellProps) => {
	const { children, error, ...cellProps } = props;

	return (
		<td {...cellProps}>
			<FormTable.FormCellInnerWrapper error={error}>
				{children}
			</FormTable.FormCellInnerWrapper>
		</td>
	);
};

export type { UnfocusableTableCellProps as CellProps };
export { UnfocusableTableCell as Cell };
