import styled from "@emotion/styled";
import * as joy from "@mui/joy";

import { Input } from "./Input";
import { theme } from "../../../ui-layout/styles/theme";

/**
 * Styles overrides when we want to display the {@link Input} inside a form table cell
 * In Joy SX format
 *
 * @param reverse if we want to "reverse" the alignment (== align to the right)
 * @returns an SX-formatted style
 */
export function getInputCellSX(reverse?: boolean) {
	return {
		height: "100%",
		minHeight: "100%",
		outline: "none",
		paddingLeft: "20px",
		border: `2px solid ${theme.palette.blue.primary}`,

		[`&.${joy.inputClasses.focused}`]: {
			outline: "none",
		},

		// Allows to align text to the right in inputs (e.g. for current expenses)
		[`& .${joy.inputClasses.input}`]: {
			textAlign: reverse ? "right" : "left",
		},

		[`&.${joy.inputClasses.root}`]: {
			borderRadius: "0",
		},
	};
}

/**
 * Styles overrides when we want to display a small {@link Input} (e.g. in {@link Pagination})
 */
export const InputSmall = styled(Input)`
	border-radius: 4px;
	height: 24px;
	min-height: 24px;
	min-width: 54px;
	padding: 3px 8px;
	width: 54px;
`;
