import { OmitKnown } from "@nna/core";
import { useTranslation } from "next-i18next";
import React from "react";

import { Inputs } from "..";
import {
	UnfocusedInputValue,
	UnfocusedInputValueProps,
} from "./UnfocusedInputValue";
import { FormTable, Tooltips } from "../../../ui-atoms/components";
import { FormCellInnerWrapperProps } from "../../../ui-atoms/components/form-table";

/** Props for {@link FocusInput} */
export interface FocusInputProps
	extends OmitKnown<FormTable.TableCellProps, "children" | "whenFocused">,
		Pick<FormCellInnerWrapperProps, "reverse"> {
	/** Is the input disabled (and thus the focus mode) */
	disabled?: boolean;
	/** Override props for the input when focused */
	input?: OmitKnown<Inputs.InlinedErrorProps, "disabled" | "placeholder">;
	/** Text to show when empty */
	placeholder?: string;
	/**
	 * Show the tooltip error on the unfocused mode?
	 *
	 * @default true
	 */
	unfocusedError?: boolean;
	/**
	 * Node to display on "unfocused" mode (Not with the input)
	 *
	 * @default UnfocusedInputValueDefault that simply prints the input value
	 */
	unfocusedValue?: React.FC<UnfocusedInputValueProps>;
}

/** A regular "text" input in a table cell (with (un-)focus modes) */
export function FocusInput(props: FocusInputProps) {
	const {
		disabled = false,
		input = {},
		placeholder,
		reverse,
		unfocusedError = true,
		unfocusedValue: UnfocusedValue = UnfocusedInputValue,
		...cellProps
	} = props;
	const { endDecorator, error, startDecorator, type, value } = input;

	const { t } = useTranslation();

	if (type === "number-currency" && !endDecorator) {
		return (
			<FocusInput
				{...props}
				input={{
					endDecorator: t("common.currency.chf"),
					...input,
				}}
			/>
		);
	}

	return (
		<FormTable.FocusableTableCell
			focused={disabled ? false : undefined}
			{...cellProps}
			whenFocused={
				<Inputs.InlinedError
					autoFocus
					{...input}
					disabled={disabled}
					sx={Inputs.Styles.getInputCellSX(reverse)}
					type={type}
				/>
			}
		>
			<FormTable.FormCellInnerWrapper
				data-testid="cell-input/focus-input/unfocused"
				disabled={disabled}
				error={!!error}
				reverse={reverse}
			>
				<UnfocusedValue
					disabled={disabled}
					endDecorator={endDecorator}
					placeholder={placeholder}
					startDecorator={startDecorator}
					type={type}
					value={value}
				/>

				{error && unfocusedError ? <Tooltips.Error {...error} /> : null}
			</FormTable.FormCellInnerWrapper>
		</FormTable.FocusableTableCell>
	);
}
