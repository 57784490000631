import styled from "@emotion/styled";
import { useField } from "formik";
import { Fragment, useEffect } from "react";
import { MultilineRowState } from "~/common/entry/common/multiline";

import { FormikMultilineRow, MultilineFormProps } from "./MultilineForm";
import { ButtonIcon, ButtonIconSizes } from "../Button/ButtonIcon";

/** Props passed to the {@link ColumnModel} 'render' function */
export interface ColumnRenderProps<T> {
	/** 'name' that should be used for form cells */
	name: string;
	reverse?: boolean;
	/** Current state of the row */
	state: MultilineRowState;
	/** Value of the row entity, that can be used to display static information */
	value: T;
}
/** Model for defining a {@link MultilineForm} column */
export interface ColumnModel<T> {
	/** A string that can be set to display as a Multiline column header */
	header?: string;
	/** A unique key, that will be used as React key + for the form 'name' attribute */
	key: string;
	/** A render function to define what's displayed in the column */
	render: (props: ColumnRenderProps<T>) => JSX.Element;
	reverse?: boolean;
}

/** Props for a {@link MultilineFormRow} */
interface MultilineFormRowProps<T, K>
	extends Pick<MultilineFormProps<T, K>, "formPrefix"> {
	/** List of column definitions */
	columns: Array<ColumnModel<T>>;
	/** The row index, needed to map rows to Formik FieldArray features */
	index: number;
	/** Formik FieldArray function to remove a row */
	remove: (index: number) => void;
}

/** Displays a {@link MultilineForm} row, based on data + column template */
export function MultilineFormRow<T, K extends string>(
	props: MultilineFormRowProps<T, K>,
) {
	const { columns, formPrefix, index, remove, ...trProps } = props;

	const namePrefix = `${formPrefix}.${index}`;
	const [, { touched: rowTouched, value: formValue }, { setValue }] =
		useField<FormikMultilineRow<T>>(namePrefix);
	const { _id: rowId, state: rowState, value: rowValue } = formValue;

	// Update the Multiline state if a row was updated.
	useEffect(() => {
		// If this is a new row or the row was not touched, don't do anything
		if (rowState !== "NONE" || !rowTouched) {
			return;
		}

		void setValue({
			_id: rowId,
			state: "UPDATE",
			value: rowValue,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to run this effect when the rowTouched changes
	}, [rowTouched]);

	// Update the Multiline state if a row was removed.
	const onRemoveClick = () => {
		if (rowState === "ADD") {
			remove(index);
			return;
		}

		void setValue({ _id: rowId, state: "DELETE" });
	};

	if (rowState === "DELETE") {
		return null;
	}

	return (
		<tr {...trProps}>
			{columns.map(({ key, render, reverse }) => (
				<Fragment key={key}>
					{render({
						name: `${namePrefix}.value.${key}`,
						value: rowValue as T, // When we render a row, we know there is a value
						state: rowState,
						reverse,
					})}
				</Fragment>
			))}

			<FixedCell>
				<ButtonWrapper>
					<ButtonIcon
						data-testid="form/multiline/row/remove"
						name="remove"
						onClick={onRemoveClick}
						size={ButtonIconSizes.SIZE24}
					/>
				</ButtonWrapper>
			</FixedCell>
		</tr>
	);
}

export const FixedCell = styled.td`
	text-align: center;
	width: 48px;
`;

// We need this because <table> are shit at styling
const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
`;
