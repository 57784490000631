import { OmitKnown } from "@nna/core";
import { useField } from "formik";
import { useTranslation } from "next-i18next";
import { ChangeEvent } from "react";

import { InputUtils } from "../../../../utils";
import { RadioValue } from "../Radio";
import { RadioGroup, RadioGroupProps } from "../RadioGroup";

/** Props for {@link RadioGroupFieldYesNo} */
export interface RadioGroupFieldYesNoProps<
	V extends RadioValue,
	N extends string,
> extends OmitKnown<RadioGroupProps<V>, "onChange" | "radios" | "value"> {
	/** Name of the input (for formik) */
	name: N;
}

/**
 * Wraps {@link RadioGroup} with Formik and preset options:
 * - A yes and a no radio button, with a boolean state value.
 *
 * We need to override radio values themselves, because radio buttons don't accept booleans.
 * But we maintain a boolean in the Formik state.
 */
export function RadioGroupFieldYesNo(
	props: RadioGroupFieldYesNoProps<RadioValue, string>,
) {
	const { t } = useTranslation();

	const { name } = props;
	const [{ onBlur, onChange: onChangeFormik, value }] =
		useField<RadioValue>(name);

	const radios = [
		{
			label: t("common.state.yes"),
			value: 1,
		},
		{
			label: t("common.state.no"),
			value: 0,
		},
	];

	// Override the `onChange` to transform the value from number (radio value) to boolean (formik value)
	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		onChangeFormik(
			InputUtils.transformChangeEvent(event, (_, event) =>
				event.target.value === "0" ? false : true,
			),
		);
	};

	return (
		<RadioGroup
			{...props}
			onBlur={onBlur}
			onChange={onChange}
			radios={radios}
			// Override the value to transform the boolean to a number
			value={value ? 1 : 0}
		/>
	);
}
