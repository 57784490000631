import { css } from "@emotion/react";
import styled from "@emotion/styled";
import * as joy from "@mui/joy";

import { filterProps } from "../../../services/styled/filterProps";
import { theme } from "../../ui-layout/styles/theme";

/** Table row color overrides */
export const TABLE_CELL_BACKGROUND_CSS = "--TableCell-headBackground";

/** Table size overrides */
export const TABLE_CELL_HEIGHT_CSS = "--unstable_TableCell-height";
export const TABLE_CELL_HEIGHT_VALUES = {
	small: "24px",
};

/**
 * The CSS variable used to set the background.
 * It is "CSS-overwrote" depending on the used mode
 */
export const TABLE_CUSTOM_BG_COLOR_CSS = "--table-bg-color";

/** The css variables used in {@link TableCustom} to get its color */
export const TABLE_CUSTOM_CSS_VAR = {
	DEFAULT: "palette-white-primary",
	HOVER: "palette-grey-20",
	STRIPE: "palette-grey-10",
} as const satisfies Record<string, joy.ThemeCssVar>;

/** Set the bg-color on hover */
const styleOnHover = css`
	&:hover {
		${TABLE_CUSTOM_BG_COLOR_CSS}: ${theme.getCssVar(
			TABLE_CUSTOM_CSS_VAR.HOVER,
		)};
	}
`;
/** Set the stripe style on even rows */
const styleStripe = ({ $hover }: TableCustomProps) => css`
	&:nth-of-type(odd) {
		${TABLE_CUSTOM_BG_COLOR_CSS}: ${theme.getCssVar(
			TABLE_CUSTOM_CSS_VAR.STRIPE,
		)};

		${$hover && styleOnHover};
	}
`;
/** Set the first column as sticky */
const styleFirstColumn = css`
	thead tr th:first-of-type {
		z-index: calc(${theme.getCssVar("zIndex-table")} + 1);
	}

	thead tr th:first-of-type,
	tbody tr td:first-of-type {
		background-color: var(${TABLE_CUSTOM_BG_COLOR_CSS});
		box-shadow: 1px 0 var(${TABLE_CUSTOM_BG_COLOR_CSS});
		left: 0;
		position: sticky;
	}
`;

// Layout to use when we display a form table
const baseLayoutFormTable = css`
	border-collapse: collapse;
	table-layout: fixed;

	thead tr th,
	tbody tr td {
		border-bottom: none !important;
		padding: 0;
	}

	/** Exceptional use of a className to target the placeholder, because there is an unknown distance between it and the tr:hover */
	tbody tr:hover > td .placeholder {
		visibility: visible;
	}
`;

const layoutFormTable = css`
	${baseLayoutFormTable}

	// Apply strict styling to the first column
	tbody tr td:first-of-type {
		/* Note that if the table has 1+ spanned th, this setting will not be work.
			This is why tables are shit at being tables, and we should use css grids. */
		padding: 0 8px 0 12px;
		width: 300px;
	}
`;

const layoutMultilineTable = css`
	${baseLayoutFormTable}

	// Apply strict styling to the first column
	tbody tr td:first-of-type {
		width: 300px;
	}
`;

// TODO: better name

/** Style Props for {@link TableCustom} */
export interface TableCustomProps {
	/** The table row will shade on hover */
	$hover?: boolean;
	/** Set some styling for multiline form layout */
	$multilineLayout?: boolean;
	/** If `true`, the first column always appear at the left of the overflow table. */
	$stickyFirstColumn?: boolean;
	/**
	 * The even row will have a different background.
	 *
	 * Note: only for even rows of a `tbody`
	 */
	$stripe?: boolean;
	/** Set some table styling for table forms */
	$tableFormLayout?: boolean;
}

/**
 * Extension of {@link joy.Table} for some style modes.
 * For instance, the default component does not manage striped rows and sticky columns.
 *
 * To set different colors :
 * - Either put this component inside a parent and override the {@link TABLE_CUSTOM_CSS_VAR cssVars}
 * - Or `styled` this component, and override its {@link TABLE_CUSTOM_CSS_VAR cssVars}
 *
 * @example
 * <TableCustom css={{[`--${theme.cssVarPrefix}-${TABLE_CUSTOM_CSS_VAR.DEFAULT}`]: "red"}} />
 */
export const TableCustom = styled(joy.Table, filterProps)<TableCustomProps>`
	${TABLE_CUSTOM_BG_COLOR_CSS}: ${theme.getCssVar(
		TABLE_CUSTOM_CSS_VAR.DEFAULT,
	)};

	table-layout: fixed;

	// Apply default background color
	// So it will be the same with sticky columns
	thead tr,
	tbody tr {
		background-color: var(${TABLE_CUSTOM_BG_COLOR_CSS});

		&.clickable {
			cursor: pointer;
		}
	}

	thead tr th,
	tbody tr td {
		border-bottom: 1px solid ${theme.palette.grey[40]} !important;
		border-top: none !important;
	}

	/* Load the layout for table form if request */
	${({ $tableFormLayout }) => $tableFormLayout && layoutFormTable}
	${({ $multilineLayout }) => $multilineLayout && layoutMultilineTable}

	// Apply 'sticky-first-column' style
	${({ $stickyFirstColumn }) => $stickyFirstColumn && styleFirstColumn}

	tbody tr {
		/* Apply 'onHover' style */
		${({ $hover }) => $hover && styleOnHover};

		/* Apply 'stripe' style */
		${props => props.$stripe && styleStripe(props)};
	}
`;
